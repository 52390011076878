import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts 

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://124.220.178.50:8084'
axios.defaults.baseURL = '/api'
// axios.defaults.baseURL = '/wechat'
// http://101.132.245.159:8080/software/index.html#
import {
  getLocalStorage, setLocalStorage, getObjectLocalStorage, setObjectLocalStorage, removeLocalStorage
} from './storage/storage'

Vue.prototype.$getLocalStorage = getLocalStorage
Vue.prototype.$setLocalStorage = setLocalStorage
Vue.prototype.$getObjectLocalStorage = getObjectLocalStorage
Vue.prototype.$setObjectLocalStorage = setObjectLocalStorage
Vue.prototype.$removeLocalStorage = removeLocalStorage

export default new Vue({
  el: '#app',
  router,
  render: h => h(App)
})

axios.interceptors.request.use(
  config => {
    if (sessionStorage.getItem('logintoken')) {
      config.headers.Authorization = sessionStorage.getItem('logintoken')
    }
    return config
  },
  function (error) {
    console.log(error)
    return Promise.reject(error)
  }
)