<!--
 * @Author: your name
 * @Date: 2021-11-26 15:49:06
 * @LastEditTime: 2021-11-26 16:35:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vant\src\App.vue
-->
<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view/>
  </div>
</template>

<script>
//    import HelloWorld from './components/HelloWorld.vue'
// // import HelloWorld from './views/address/index.vue

// export default {
//   name: 'App',
//   components: {
//     HelloWorld
//   }
// }

export default {
  name: 'App'
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  height: 100vh;
  /* background-color: #e2f0d9; */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 30px; */
}
</style>
