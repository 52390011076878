import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '登录', keepAlive: false }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue'),
    meta: { title: '注册', keepAlive: false }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/forget/index.vue'),
    meta: { title: '注册', keepAlive: false }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: { title: '首页', keepAlive: false },
    children: [
      {
        path: '/userManage',
        name: 'userManage',
        meta: { title: '用户管理', keepAlive: false },
        component: () => import('@/views/home/userManage/index.vue'),
      },
      {
        path: '/contentManage',
        name: 'contentManage',
        meta: { title: '闲置商品', keepAlive: false },
        component: () => import('@/views/home/contentManage/index.vue'),
      },
      {
        path: '/productManage',
        name: 'productManage',
        meta: { title: '积分商品', keepAlive: false },
        component: () => import('@/views/home/productManage/index.vue'),
      },
      {
        path: '/cartManage',
        name: 'cartManage',
        meta: { title: '商品订单', keepAlive: false },
        component: () => import('@/views/home/cartManage/index.vue'),
      },
      {
        path: '/orderManage',
        name: 'orderManage',
        meta: { title: '积分兑换', keepAlive: false },
        component: () => import('@/views/home/orderManage/index.vue'),
      },
      {
        path: '/classificationManage',
        name: 'classificationManage',
        meta: { title: '商品分类', keepAlive: false },
        component: () => import('@/views/home/classificationManage/index.vue'),
      },
      {
        path: '/groupManage',
        name: 'groupManage',
        meta: { title: '论坛分类', keepAlive: false },
        component: () => import('@/views/home/groupManage/index.vue'),
      },
      {
        path: '/forumManage',
        name: 'forumManage',
        meta: { title: '论坛管理', keepAlive: false },
        component: () => import('@/views/home/forumManage/index.vue'),
      },
      {
        path: '/scoreManage',
        name: 'scoreManage',
        meta: { title: '积分商品', keepAlive: false },
        component: () => import('@/views/home/scoreManage/index.vue'),
      },
      {
        path: '/noticeManage',
        name: 'noticeManage',
        meta: { title: '通知管理', keepAlive: false },
        component: () => import('@/views/home/noticeManage/index.vue'),
      },
      {
        path: '/swipperManage',
        name: 'swipperManage',
        meta: { title: '轮播图管理', keepAlive: false },
        component: () => import('@/views/home/swipperManage/index.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 })
})

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // if(!sessionStorage.getItem('username')){
  //   if(to.path !== '/login'){
  //     next('/login')
  //   }
  // }
  // console.log("store:" + store.state.token)
  // if(to.matched.some(r => r.meta.requireAuth)){
  //   if(this.$store.state.token){
  //     next()
  //   }else{
  //     next({
  //       path:'/Login',
  //       query:{
  //         redirect:to.fullPath
  //       }
  //     })
  //   }
  // }else{
  //   next();
  // }
  next();
});


export default router